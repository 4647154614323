import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu'

import iconFacebook from '../images/icon-facebook.svg';
import iconInstagram from '../images/icon-instagram.svg';

import styles from '../styles/navigation.module.scss';
import '../styles/navigation.react-burger-menu.scss';

const Navigation = () => (
  <React.Fragment>
    <div className={styles.desktop}>
      <MainNavigation />
      <SocialNavigation />
    </div>
    <div className={styles.mobile}>
      <Menu
        right
        width={'240px'}
      >
        <MainNavigation />
        <SocialNavigation />
      </Menu>
    </div>
  </React.Fragment>
)

const MainNavigation = () => (
	<nav className={styles.navigation}>
	  <Link to='/#producten'>Producten</Link>
	  <Link to='/#recepten'>Recepten</Link>
	  <Link to='/#verkooppunten'>Verkooppunten</Link>
	  <Link to='/#het-verhaal'>Het Verhaal</Link>
	  <Link to='/#contact'>Contact</Link>
	</nav>
)

const FooterNavigation = () => (
  <nav className={styles.footerNavigation}>
    <Link to='/'>Home</Link>
    <Link to='/#producten'>Producten</Link>
    <Link to='/#recepten'>Recepten</Link>
    <Link to='/#verkooppunten'>Verkooppunten</Link>
    <Link to='/#het-verhaal'>Het Verhaal</Link>
    <Link to='/#contact'>Contact</Link>
  </nav>
)

const SocialNavigation = () => (
  <nav className={styles.socialNavigation}>
    <a href='https://www.facebook.com/zaansesambal/'>
      <img src={iconFacebook} alt='' />
    </a>
    <a href='https://www.instagram.com/zaansesambal/'>
      <img src={iconInstagram} alt='' />
    </a>
  </nav>
)

export {Navigation, FooterNavigation, SocialNavigation}
