import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { FooterNavigation, SocialNavigation } from './navigation';

import styles from '../styles/footer.module.scss';

import logoMosb from '../images/logo-ministry-of-spicy-business.svg';
import logoLab44 from '../images/logo-lab-44.svg';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        footer: contentfulContent(slug: { eq: "contact-informatie" }) {
          id
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer id='contact' className={styles.footer}>
          <div className={styles.footerInnerContainer}>
            <div
              className={styles.html}
              dangerouslySetInnerHTML={{
                __html: data.footer.body.childMarkdownRemark.html,
              }}
            />
            <div className={styles.lab44}>
              <p className={styles.logoLab44}>
                <img src={logoLab44} alt='Lab 44' />
                <br/>
                <a href="http://www.lab-44.nl">www.lab-44.nl</a>
              </p>
            </div>
            <div>
              <img
                className={styles.logoMosb}
                src={logoMosb}
                alt='Ministry of Spicy Business' />
            </div>
            <div className={styles.footerNavigation}>
              <FooterNavigation />
            </div>
            <div>
              <SocialNavigation />
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Footer.propTypes = {

}

export default Footer
