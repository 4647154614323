import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Navigation, SocialNavigation } from './navigation';

import logoMosb from '../images/logo-ministry-of-spicy-business.svg';
import styles from '../styles/header.module.scss';

const Header = ({ siteTitle }) => (
  <div
    className={styles.header}
  >
    <div
      className={styles.headerInnerContainer}
    >
      <div className={styles.logo}>
        <h1>
          <Link
            to='/'
          >
            <img src={logoMosb} alt='Ministry of Spicy Business' />
            <span className={styles.siteTitle}> {siteTitle}</span>
          </Link>
        </h1>
      </div>

      <Navigation />

    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
